body[data-theme="dark"] {  

   @import '../dark-slider.scss';

   .slider-tab {
      background-color: #8C8C8C;
   }


.main_navbar_list {

	color: #fff;
}

   .title-styles {
      color: white;
   }

   .header-icon {
      color: #3C342C;
      opacity: 0.8;
   }

   .slider-image {
      border: 5px solid #8C8C8C;
   }

   .language {
      background-color: #494949;
   }

   .link-href {
      color: white;
   }

   .project-date {
      background-color: #918e97;
      color: white;
   }

   header {
      background-color: #494949;
      h1 {
         color: white;
      }
   }

   #about {
      background-color: #7f7f7f;
      h1 span {
         color: white;
      }
      .polaroid span {
         background:  #6d6d6d;
      }
      .card {
         background: #6d6d6d;
         color: white;
      }
   }

   #contactMe {

      background: #6d6d6d;
      color: #fff;
   }

   .btn-beige {
      background-color: #000 !important;
      color: #fff;
     }
     
     .btn-beige:hover {
      opacity: .7;
     }

   #contactMe h1 {
      padding-top: 2.5%;
      font: 18px/24px 'opensans-bold', sans-serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      color: #fff;
      text-align: center;
   }

   #portfolio {
      background: #5b5b5b;
      .section-title {
         color: white !important;
      }
      .foto div {
         background: #6d6d6d;
      }
      .project-title-settings {
         color: white;
      }
   }

   #resume {
      filter: brightness(80%);
      background: #5b5b5b;
      .section-title {
         color: white !important;
      }

      .experience-badge {
         background: #919191 !important;
         color: white;
      }

      .main-badge {
         background: #919191 !important;
      }

      .vertical-timeline-element-date {
         color: white;
      }

      .vertical-timeline-element-icon {
         background: #919191 !important;
      }
      @media only screen and (max-width: 1169px) { 
         .vertical-timeline-element-date {
            color: black;
         }
      }
   }

   .modal-inside .modal-content {
      background: #5b5b5b;
      color: white;
   }

   .close-icon {
      color: white;
   }

   .buttons {
      margin-top: 50px;
      text-align: center;
      border-radius:30px;
     }
     
     $cyan: #000;
     $dark: #fff;
     $borderW: 2px;
     
     .blob-btn {
      $numOfBlobs: 4;
      z-index: 1;
      position: relative;
      padding: 20px 46px;
      margin-bottom: 30px;
      text-align: center;
      text-transform: uppercase;
      color: $dark;
      font-size: 16px;
      font-weight: bold;
      background-color: transparent;
      outline: none;
      border: none;
      transition: color 0.5s;
      cursor: pointer;
      border-radius:30px;
      
      &:before {
        content: "";
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: $borderW solid $cyan;
        border-radius:30px;
      }
      
      &:after {
        content: "";
        z-index: -2;
        position: absolute;
        left: $borderW*1.5;
        top: $borderW*1.5;
        width: 100%;
        height: 100%;
     
        transition: all 0.3s 0.2s;
        border-radius:30px;
      }
      
      &:hover {
        color: $dark;
        border-radius:30px;
        
        &:after {
         transition: all 0.3s;
         left: 0;
         top: 0;
         border-radius:30px;
        }
      }
      
      &__inner {
        z-index: -1;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius:30px;
        background:#919191;
      }
      
      // additional container created, because in FF blobs are breaking overflow:hidden of element with svg gooey filter
      &__blobs {
        position: relative;
        display: block;
        height: 100%;
        filter: url('#goo');
      }
      
      &__blob {
        position: absolute;
        top: $borderW;
        width: 100% / $numOfBlobs;
        height: 100%;
        background: $cyan;
        border-radius: 100%;
        transform: translate3d(0,150%,0) scale(1.7);
        transition: transform 0.45s;
        
        @supports(filter: url('#goo')) {
         transform: translate3d(0,150%,0) scale(1.4);
        }
        
        @for $i from 1 through $numOfBlobs {
         &:nth-child(#{$i}) {
           left: ($i - 1) * (120% / $numOfBlobs);
           transition-delay: ($i - 1) * 0.08s;
         }
        }
        
        .blob-btn:hover & {
         transform: translateZ(0) scale(1.7);
         
         @supports(filter: url('#goo')) {
           transform: translateZ(0) scale(1.4);
         }
        }
      }
   }
}